window.klickart = window.klickart || {};

const maxRequestRetries = 5;
let retries = 0;

const hasRequestConfig = () => {
  return window.klickart.analytics
      && window.klickart.analytics.deliveryUrl
      && window.klickart.analytics.sessionId;
};

const sendSubscriptionEmail = () => {
  if(!hasRequestConfig()) {
    retryRequest();
    return;
  }

  const client = new XMLHttpRequest();
  const timeElapsed = window.performance.now()/1000;
  client.onload = clearSubscription;
  client.open('POST', window.klickart.analytics.deliveryUrl, false);

  try {
    client.send(JSON.stringify({
      'subscriptionEmail': window.klickart.subscriptionEmail || null,
      'sessionId': window.klickart.analytics.sessionId,
      'timeElapsed': timeElapsed,
    }));
  } catch(error) {
    console.error('failed to publish subscription in analytics');
    console.error(error);
  }
}

const retryRequest = () => {
  if(retries >= maxRequestRetries) {
    console.warn('exceed analytics email subscription request retries');
    return;
  }

  setTimeout(sendSubscriptionEmail, 500);
  retries += 1;
};

const clearSubscription = () => {
  window.klickart.subscriptionEmail = null;
}

const klickartPublicAnalyticsSubscription = () => {
  window.klickart = window.klickart || {};

  const formSelector = 'form[klickart-email-subscription-form]';
  const forms = document.querySelectorAll(formSelector);
  const emailRegex = /(email_\d+|email|inf_field_Email|cmp1|kmbSubscribe\[email\]|mauticform\[email\])/ig;

  forms.forEach((form) => {
    form.addEventListener('submit', () => {
      const inputs = form.querySelectorAll('input');
      const inputsArray = Array.from(inputs);

      const emailField = inputsArray.filter((input) => {
        return input.name.match(emailRegex);
      });

      if (emailField.length > 0) {
        window.klickart.subscriptionEmail = emailField[0].value;
        sendSubscriptionEmail();
      }
    });
  });
};

export default klickartPublicAnalyticsSubscription;

window.klickart = window.klickart || {};

const maxRequestRetries = 5;
const linkCounterSelector = '[klickart-analytics-count-conversion]';
const linkAttributeSelector = 'klickart-analytics-count-conversion';
let retries = 0;

const hasRequestConfig = () => {
  return window.klickart.analytics
      && window.klickart.analytics.deliveryUrl
      && window.klickart.analytics.sessionId;
};

const clearClick = () => {
  window.klickart.clickIdentifier = null;
};

const sendClicks = () => {
  if (!hasRequestConfig()) {
    retryRequest();
    return;
  }

  const client = new XMLHttpRequest();
  const timeElapsed = window.performance.now()/1000;
  client.onload = clearClick;
  client.open('POST', window.klickart.analytics.deliveryUrl, false);

  try {
    client.send(JSON.stringify({
      'clickIdentifier': window.klickart.clickIdentifier,
      'sessionId': window.klickart.analytics.sessionId,
      'timeElapsed': timeElapsed,
    }));
  } catch (error) {
    console.error('failed to publish click in analytics');
    console.error(error);
  }
};

const retryRequest = () => {
  if(retries >= maxRequestRetries) {
    console.warn('exceed analytics click counter request retries');
    return;
  }

  setTimeout(sendClicks, 500);
  retries += 1;
};

const incrementClick = (event) => {
  const identifier = event.currentTarget.getAttribute(linkAttributeSelector);
  window.klickart.clickIdentifier = identifier;
  sendClicks();
};

const klickartPublicAnalyticsClickCounter = ()=> {
  window.klickart.clickIndetifier = null;

  const linksToCount = document.querySelectorAll(linkCounterSelector);

  linksToCount.forEach((link) => {
    link.addEventListener('click', incrementClick);
  });
};

export default klickartPublicAnalyticsClickCounter;
